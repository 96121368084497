.loginForm{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginForm form{
    width: 100%;
    padding: 15px 45px;
}

.title{
    font-size: 28px;
    color: #326dff;
    font-weight: 700;
    padding-bottom: 5px;
}

@media (max-width: 768px) { 
    .wrapper{
        width: 100%;
        height: 350px;
    }
    .wrapper:nth-child(1){
       height: 250px;
    }
 }